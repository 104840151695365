import React, { useState } from 'react';
import { withStyles } from '@material-ui/styles';
import useHomepage from './useHomepage';
import styles from './style';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
// import yesWeDoThatLight from 'images/yesWeDoThatLight.png';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import { Link } from 'gatsby';
// import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Logo from 'images/sparklabs-logo.png';
import sparkLogo from 'images/sparklabs-logo-white.png';
import Typist from 'react-typist';
// import devImg from 'images/devices.png';

type Props = {
  classes: Object,
};

const Homepage = ({ classes }: Props) => {
  const { allContentfulHomepage } = useHomepage();
  const {
    partnerList,
    threeColumnCallout,
    twoColumnBody,
    whatWeDo,
    hero,
  } = allContentfulHomepage.nodes[0];

  const CycleTypist = ({ words }) => {
    const [index, setIndex] = useState(0);

    const word = words[index % words.length];
    const Cycle = () => (
      <Typist
        onTypingDone={() => {
          setIndex((i) => i + 1);
        }}>
        {word}
        <Typist.Backspace count={word.length} delay={word.length * 100} />
      </Typist>
    );

    return <Cycle />;
  };

  return (
    <>
      <div key='background' id='background' className={classes.headerContainer}>
        <div id='background-inside' className={classes.headerInside}>
          <img src={Logo} alt='logo' id='top' className={classes.headerLogo} />
          <Grid container className={classes.heroContainer}>
            <Grid
              item
              xs={12}
              md={6}
              sm={6}
              className={classes.heroWeDoContainer}>
              <h2 className={classes.heroWeDo}>We Do That*</h2>
              <Typography className={classes.heroWeDoType}>
                <CycleTypist words={hero.typedCallouts} />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.heroSide} />
          </Grid>
        </div>
      </div>

      {/* Our Niche */}
      <div className={classes.center}>
        <div className={classes.twoColumnContainer} id='niche'>
          {twoColumnBody.map((body, index) => {
            if (index % 2 !== 0) {
              return (
                <Grid
                  key={'twocol' + Number(index).toString()}
                  container
                  spacing={8}>
                  <Grid
                    item
                    className={classes.twoColumnGridItem}
                    key={'twocol' + Number(index).toString() + '-4'}
                    xs={12}
                    md={6}>
                    <Typography className={classes.header2}>
                      {' '}
                      {body.header}{' '}
                    </Typography>
                    <Typography className={classes.subHeader1} variant='h1'>
                      {' '}
                      {body.subHeader}{' '}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    key={'twocol' + Number(index).toString() + '-3'}
                    xs={12}
                    md={6}>
                    <Typography className={classes.paragraph}>
                      {' '}
                      {body.paragraph.paragraph}{' '}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }
            return null;
          })}
        </div>
      </div>

      {/* partner lists */}
      <div className={classes.partnersContainer}>
        {partnerList.map((partner) => (
          <a
            key={'partner-' + partner.name}
            className={classes.partners}
            href={partner.websiteUrl}
            target='_blank'
            rel='noreferrer'
            title={partner.name}>
            <img
              className={classes.img}
              src={partner.logo.file.url}
              alt={partner.name}
            />
          </a>
        ))}
      </div>

      {/* Who we work with */}
      <Paper id='work' className={classes.threeColumnContainer}>
        <Typography
          variant='h4'
          className={classes.header2}
          style={{ textAlign: 'center' }}>
          Who we work with
        </Typography>
        {threeColumnCallout.map((callout) => (
          <div
            style={{ display: 'inline-block', justifyContent: 'center' }}
            key={callout.heading}>
            <Card className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={1} className={classes.callContainer}>
                  <Grid item>
                    <div className={classes.callImgContainer}>
                      <img
                        src={callout.image.file.url}
                        alt={callout.heading}
                        className={classes.callImg}
                      />
                    </div>
                  </Grid>
                  <Grid key={callout.heading}>
                    <div className={classes.textContainer}>
                      <Typography variant='h3' className={classes.header}>
                        {callout.heading}
                      </Typography>
                      <Typography className={classes.copy}>
                        {callout.paragraph.paragraph}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </div>
        ))}
      </Paper>

      {/* Our Philosophy */}
      <div className={classes.center}>
        <div className={classes.twoColumnContainer} id='philosophy'>
          {twoColumnBody.map((body, index) => {
            if (index % 2 === 0) {
              return (
                <Grid
                  key={'twocol' + Number(index).toString()}
                  container
                  spacing={8}>
                  <Grid
                    item
                    className={classes.order2}
                    key={'twocol' + Number(index).toString() + '-2'}
                    xs={12}
                    md={6}>
                    <Typography className={classes.paragraph}>
                      {' '}
                      {body.paragraph.paragraph}{' '}
                    </Typography>
                  </Grid>

                  <Grid
                    item
                    className={classes.order1}
                    key={'twocol' + Number(index).toString() + '-1'}
                    xs={12}
                    md={6}>
                    <Typography
                      className={classes.header2}
                      style={{ textAlign: 'left' }}>
                      {' '}
                      {body.header}{' '}
                    </Typography>
                    <Typography
                      className={classes.subHeader2}
                      style={{ textAlign: 'left' }}
                      variant='h1'>
                      {' '}
                      {body.subHeader}{' '}
                    </Typography>
                  </Grid>
                </Grid>
              );
            }
            return null;
          })}
        </div>
      </div>

      {/* Our Services */}
      <div className={classes.whatWeDoBackground}>
        <Typography variant='h4' className={classes.header3} id='services'>
          Our Services
        </Typography>
        <div className={classes.whatWeDoContainer}>
          <Grid container spacing={1} alignItems='center'>
            {whatWeDo.map((desc, index) => (
              <Grid
                item
                key={'whatwedo' + Number(index).toString()}
                xs={12}
                sm={6}
                md={6}
                lg={6}>
                <Grid container spacing={1} className={classes.serviceGrid}>
                  <Grid
                    item
                    key={'whatwedo' + Number(index).toString() + '2'}
                    xs={12}>
                    <Paper className={classes.servicePaper}>
                      <Grid container spacing={1}>
                        <Grid
                          className={classes.iconGrid}
                          item
                          variant='h2'
                          key={'whatwedo' + Number(index).toString() + '-1'}
                          xs={12}
                          sm={12}
                          md={2}
                          lg={2}>
                          <img
                            className={classes.imgLogo}
                            src={desc.image.file.url}
                            alt={desc.heading}
                          />
                        </Grid>

                        <Grid item lg={10} md={10} xs={12} sm={12}>
                          <Typography
                            variant='h3'
                            className={classes.headerNoMargin}>
                            {' '}
                            {desc.heading}{' '}
                          </Typography>
                          <Typography className={classes.paragraph2}>
                            {' '}
                            {desc.paragraph.paragraph}{' '}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>

      {/* Contact Us */}
      <div>
        <Typography variant='h4' className={classes.header4}>
          Contact Us
        </Typography>
        <Grid
          container
          direction='row'
          spacing={2}
          justify='space-evenly'
          id='contact'>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Typography variant='h2' className={classes.doThat}>
              Yes We Do That!
            </Typography>
            <Typography className={classes.address}>
              <span className={classes.street}>724 Bielenberg Drive #13; </span>
              <span className={classes.addressCityCountryZip}>
                St. Paul, MN 55125
              </span>
            </Typography>
            <Typography className={classes.email}>
              <a
                target='_blank'
                rel='noreferrer'
                href='mailto:yeswedothat@thesparklaboratory.com'
                className={classes.email}>
                YesWeDoThat@TheSparklaboratory.com
              </a>
            </Typography>
          </Grid>
        </Grid>
      </div>

      {/* Footer */}
      <div>
        <Grid container className={classes.root}>
          <Grid item xs={6} sm={5} md={2} lg={5} style={{ textAlign: 'left' }}>
            <div className={classes.logoContainer}>
              <Link to='/#top'>
                <img
                  src={sparkLogo}
                  className={classes.sparkImg}
                  alt='sparkImg'
                />
              </Link>
            </div>
          </Grid>

          <Grid item xs={6} sm={7} md={10} lg={7} className={classes.linkCont}>
            <Link to='/#niche' className={classes.link}>
              Our Niche
            </Link>
            <Link to='/#work' className={classes.link}>
              Who We Work With
            </Link>
            <Link to='/#philosophy' className={classes.link}>
              Our Philosophy
            </Link>
            <Link to='/careers' className={classes.link}>
              Careers
            </Link>
            <Link to='/#services' className={classes.link}>
              Our Services
            </Link>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.copyright}>
                Copyright All Rights Reserved © 2021
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withStyles(styles)(Homepage);
