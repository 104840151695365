import { useStaticQuery, graphql } from 'gatsby';

const useHomepage = () => {
  return useStaticQuery(
    graphql`
      query allContentHomepage {
        allContentfulHomepage {
          nodes {
            twoColumnBody {
              header
              subHeader
              paragraph {
                paragraph
              }
            }
            threeColumnCallout {
              heading
              paragraph {
                paragraph
              }
              image {
                file {
                  url
                }
              }
            }
            whatWeDo {
              heading
              paragraph {
                paragraph
              }
              image {
                file {
                  url
                }
              }
            }
            partnerList {
              name
              websiteUrl
              logo {
                file {
                  url
                }
              }
            }
            hero {
              background {
                file {
                  url
                }
              }
              typedCallouts
              video {
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  );
};

export default useHomepage;
