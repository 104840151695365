import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';

import { Animated, LinkList } from 'components';

import content from './content';

const { links } = content;

const CareersItem = ({ job }) => {
  return (
    <>
      <Fade in timeout={1000}>
        <Typography variant='h1' color='textPrimary'>
          <Animated>{job?.state?.choice?.jobTitle}</Animated>
        </Typography>
      </Fade>
      <Typography variant='h3' color='textPrimary'>
        {job?.state?.choice?.jobDescription?.jobDescription}
      </Typography>
      <strong>
        <a
          target='_blank'
          rel='noreferrer'
          href='mailto:admin@thesparklaboratory.com'>
          Send your resume here
        </a>
      </strong>
      <LinkList links={links} />
    </>
  );
};

export default CareersItem;
