import React from 'react';
import { makeStyles } from '@material-ui/styles';
import yesWeDoThat from 'images/yesWeDoThat.png';
import {
  Grid,
  Typography
 } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  img: {
    margin: "1em auto",
    width: 300,
    height: 300,
  },
  typography: {
    fontFamily: 'Work Sans',
    fontWeight: 800,
    fontSize: '36px',
    marginRight: '2em',
  },
}));

export default function Welcome() {
  const classes = useStyles();

  return (
    <div>
      <Grid container className={classes.img}>
        <Grid item>
          <Typography>
            <img src={yesWeDoThat} alt="tagline" />
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Typography className={classes.typography}>
          A group of entrepreneurs.
        </Typography>
      </Grid>
    </div>
  );
}
