const styles = ({ typography }) => ({
  container: {
    padding: `${typography.pxToRem(32)} 0`,
  },
  subHeaderContainer: {
    paddingBottom: 10,
    paddingLeft: 5,
  },
  headerContainer: {
    padding: 5,
  },
});

export default styles;
