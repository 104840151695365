import React from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { withStyles } from '@material-ui/styles';
import styles from './style';
import { Animated, LinkList, Tabs } from 'components';

import content from './content';

const { title, subtitle, links } = content;

type Props = {
  classes: Object,
};

const Careers = ({ classes }: Props) => {
  return (
    <>
      <LinkList links={links} />
      <div className={classes.headerContainer}>
        <Fade in timeout={1000}>
          <Typography variant='h1' color='textPrimary'>
            <Animated>{title}</Animated>
          </Typography>
        </Fade>
        <Typography
          className={classes.subHeaderContainer}
          variant='h3'
          color='textPrimary'>
          {subtitle}
        </Typography>
      </div>
      <Tabs />
    </>
  );
};

export default withStyles(styles)(Careers);
