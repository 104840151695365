import { IoIosArrowBack } from 'react-icons/io';

const content = {
  title: 'Careers.',
  subtitle: 'Build cool stuff for awesome clients and make $ while doing it.',
  links: [
    {
      to: '/',
      text: 'Back Home',
      Icon: IoIosArrowBack,
      newTab: false,
    },
  ],
};

export default content;
