import yes from 'images/yes.svg';
import bgImg from 'images/stripes-background.png';
import devImg from 'images/devices.png';

const styles = ({ breakpoints, typography }) => ({
  container: {
    padding: `${typography.pxToRem(32)} 0`,
  },
  header: {
    color: '#17252A',
    textTransform: 'initial',
    fontFamily: 'Nunito Sans',
    fontStyle: 'inherit',
    textAlign: 'left',
    fontWeight: 600,
    marginTop: 30,
    marginBottom: 20,
  },
  headerNoMargin: {
    color: '#17252A',
    textTransform: 'initial',
    fontFamily: 'Nunito Sans',
    fontStyle: 'inherit',
    textAlign: 'left',
    fontWeight: 600,
    marginBottom: 10,
    [breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  header2: {
    fontSize: `${typography.pxToRem(24)}`,
    fontWeight: 390,
    color: '#919191',
    textAlign: 'right',
    lineHeight: 2,
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontStyle: 'inherit',
    fontFamily: 'Nunito Sans',
    marginBottom: 20,
    [breakpoints.down('sm')]: {
      textAlign: 'left',
      marginBottom: 18,
    },
  },
  header3: {
    fontSize: `${typography.pxToRem(24)}`,
    fontWeight: 390,
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontStyle: 'inherit',
    fontFamily: 'Nunito Sans',
    letterSpacing: '0.8px',
    textAlign: 'center',
    color: '#fff',
    paddingTop: 80,
    paddingBottom: 30,
    [breakpoints.down('sm')]: {
      paddingTop: 50,
      paddingBottom: 15,
    },
  },
  dynamicImage: {
    maxWidth: '100%',
    maxHeight: 'auto',
    textAlign: 'center',
    marginLeft: '5em',
  },
  dynamicImage2: {
    maxWidth: '100%',
    maxHeight: 'auto',
  },
  subHeader1: {
    color: '#17252A',
    fontFamily: 'Nunito Sans',
    fontWeight: 600,
    textTransform: 'initial',
    textAlign: 'right',
    [breakpoints.down('xs')]: {
      fontSize: 35,
      lineHeight: '45px',
      textAlign: 'left',
    },
    [breakpoints.between('sm', 'md')]: {
      fontSize: 35,
      textAlign: 'left',
    },
    [breakpoints.between('md', 'lg')]: {
      fontSize: 48,
      width: '100%',
      float: 'right',
      textAlign: 'right',
    },
    [breakpoints.up('lg')]: {
      fontSize: 60,
      width: '90%',
      float: 'right',
    },
  },
  subHeader2: {
    color: '#17252A',
    fontWeight: 600,
    textTransform: 'initial',
    textAlign: 'left',
    fontFamily: 'Nunito Sans',
    [breakpoints.down('xs')]: {
      fontSize: 35,
      lineHeight: '45px',
      textAlign: 'left',
    },
    [breakpoints.between('sm', 'md')]: {
      fontSize: 35,
      textAlign: 'left',
    },
    [breakpoints.between('md', 'lg')]: {
      fontSize: 48,
      width: '100%',
      float: 'right',
      textAlign: 'right',
    },
    [breakpoints.up('lg')]: {
      fontSize: 60,
      width: '90%',
      float: 'left',
    },
  },
  partnersContainer: {
    maxWidth: `${typography.pxToRem(1140)}`,
    margin: '0 auto',
    marginBottom: '8em',
    textAlign: 'center',
  },
  colGrid: {
    justifyContent: 'center',
    textAlign: 'right',
    alignItems: 'center',
    margin: 40,
  },
  twoColumnContainer: {
    marginTop: 20,
    marginBottom: 60,
    width: '80%',
    justifyContent: 'center',
  },
  paper: {
    textAlign: 'center',
    backgroundColor: '#EEF4F5',
    marginBottom: 60,
    paddingTop: 60,
    paddingBottom: 60,
  },
  serviceGrid: {
    marginRight: 20,
    borderRadius: 15,
    padding: 20,
  },
  servicePaper: {
    margin: 10,
    padding: 20,
    borderRadius: 15,
    minHeight: 200,
    [breakpoints.between('xs', 'sm')]: {
      minHeight: '332px',
      alignItems: 'center',
      display: 'flex',
    },
    [breakpoints.between('md', 'lg')]: {
      minHeight: '280px',
      padding: '50px 30px',
    },
  },
  card: {
    justifyContent: 'center',
    maxWidth: 330,
    height: 600,
    margin: 12,
    [breakpoints.down('xs')]: {
      height: 575,
    },
    [breakpoints.between('sm', 'md')]: {
      maxWidth: 500,
      height: 550,
    },
  },
  callContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  callImg: {
    width: '100%',
  },
  callImgContainer: {
    maxHeight: '14rem',
    overflow: 'hidden',
    [breakpoints.between('sm', 'md')]: {
      maxHeight: '18rem',
    },
  },
  cardContent: {
    padding: 0,
  },
  copy: {
    fontFamily: 'Work Sans',
    fontSize: '18px',
    textAlign: 'left',
    lineHeight: 1.5,
    color: '#919191',
  },
  paragraph: {
    fontFamily: 'Work Sans',
    fontSize: '18px',
    textAlign: 'left',
    lineHeight: 2,
    color: '#919191',
  },
  paragraph2: {
    fontFamily: 'Work Sans',
    fontSize: '16px',
    lineHeight: '2em',
    verticalAlign: 'baseline',
    fontWeight: 400,
    color: '#919191',
  },
  img: {
    verticalAlign: 'middle',
    padding: `${typography.pxToRem(15)}`,
    maxHeight: `${typography.pxToRem(110)}`,
  },
  imgLogo: {
    maxHeight: '40px',
  },
  textContainer: {
    paddingRight: '2rem',
    paddingLeft: '1.8rem',
    paddingBottom: '1rem',
    paddingTop: 0,
  },
  twoColumnGridItem: {
    [breakpoints.down('sm')]: {
      paddingBottom: '0!important',
    },
  },
  threeColumnContainer: {
    textAlign: 'center',
    margin: '0 auto',
    backgroundColor: '#EEF4F5',
    paddingTop: 100,
    marginBottom: 60,
    paddingBottom: 60,
  },
  whatWeDoBackground: {
    // maxWidth: `${typography.pxToRem(1300)}`,
    margin: '0 auto',
    marginTop: '2em',
    marginBottom: '2em',
    backgroundImage: 'url(' + require('images/triangles.png') + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    justifyContent: 'center',
  },
  whatWeDoContainer: {
    maxWidth: `${typography.pxToRem(1300)}`,
    margin: '0 auto',
    justifyContent: 'center',
  },
  weDoThatBg: {
    position: 'absolute',
    backgroundImage: 'url(' + require('images/yesWeDoThat.png') + ')',
    backgroundSize: 'contain',
    backgroundPosition: '20% center',
    backgroundRepeat: 'no-repeat',
    left: 0,
    right: 0,
    width: '100%',
    height: '20em',
    opacity: '0.25',
  },
  address: {
    color: '#808080',
    fontWeight: 400,
    fontSize: 18,
    letterSpacing: '0.23px',
    textAlign: 'center',
    fontFamily: 'Nunito Sans',
    marginBottom: '1.5em',
  },
  addressCityCountryZip: {
    [breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  email: {
    fontWeight: 400,
    letterSpacing: '0.23px',
    fontSize: 18,
    color: '#1da8e1',
    textAlign: 'center',
    marginBottom: 100,
    fontFamily: 'Nunito Sans',
  },
  doThat: {
    textAlign: 'center',
    fontWeight: 700,
    fontFamily: 'Nunito Sans',
    marginBottom: 30,
  },
  header4: {
    fontSize: 18,
    letterSpacing: 2,
    fontWeight: 700,
    color: '#919191',
    textAlign: 'center',
    verticalAlign: 'baseline',
    textTransform: 'uppercase',
    fontStyle: 'inherit',
    fontFamily: 'Nunito Sans',
    marginTop: 80,
    marginBottom: 20,
  },
  root: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    backgroundColor: '#444444',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 300,
    height: 'auto',
    padding: '0.5em 1em 2em 1em',
  },
  link: {
    marginRight: '1em',
    marginLeft: '1em',
    fontFamily: 'Nunito Sans',
    fontSize: 20,
    color: '#fff',
    textTransform: 'none',
    textDecoration: 'none',
    [breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 0,
      marginRight: 0,
      fontSize: 16,
      lineHeight: '35px',
    },
  },
  container2: {
    fontSize: 20,
    textAlign: 'right',
    paddingTop: '1em',
    alignItems: 'center',
  },
  icon: {
    fontSize: '30px',
    color: '#ffffff',
  },
  iconGrid: {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
    [breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      marginBottom: 15,
    },
  },
  center: {
    textAlign: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  copyright: {
    color: '#fff',
    textAlign: 'right',
    [breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  sparkImg: {
    height: '100%',
  },
  linkItem: {
    textAlign: 'right',
  },
  linkCont: {
    flex: 1,
    flexDirection: 'row',
    textAlign: 'right',
  },
  headerContainer: {
    backgroundImage: `url("${bgImg}")`,
    backgroundColor: '#fff',
    backgroundPosition: 'top left',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    top: '0',
    right: '0',
    bottom: '0',
    left: '0',
    minHeight: 778,
    paddingTop: '54%',
  },
  headerInside: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  headerLogo: {
    position: 'absolute',
    left: 50,
    top: 50,
    minWidth: 225,
    width: '19%',
    height: 'auto',
  },
  heroContainer: {
    paddingTop: 200,
  },
  heroWeDoContainer: {
    backgroundImage: `url("${yes}")`,
    backgroundPosition: 'left 41px center',
    backgroundSize: '50%',
    backgroundRepeat: 'no-repeat',
    minHeight: 300,
    position: 'relative',
    [breakpoints.between('sm', 'md')]: {
      minHeight: 335,
      backgroundSize: '80%',
    },
  },
  heroWeDo: {
    fontWeight: 500,
    fontFamily: 'Oswald',
    color: '#fff',
    textTransform: 'uppercase',
    position: 'absolute',
    top: '20%',
    [breakpoints.down('xs')]: {
      left: '25%',
      fontSize: 47,
    },
    [breakpoints.between('sm', 'md')]: {
      top: '25%',
      left: '33%',
      fontSize: '50px',
    },
    [breakpoints.between('md', 'lg')]: {
      fontSize: 60,
      top: '31%',
      left: '39%',
    },
    [breakpoints.up('lg')]: {
      fontSize: 95,
      top: '21%',
      left: '21%',
    },
  },
  heroWeDoType: {
    fontFamily: 'Oswald',
    color: '#fff',
    position: 'absolute',
    fontSize: 20,
    fontWeight: 500,
    [breakpoints.down('xs')]: {
      left: '25%',
      top: '63%',
    },
    [breakpoints.between('sm', 'md')]: {
      top: '55%',
      left: '33%',
    },
    [breakpoints.between('md', 'lg')]: {
      left: '39%',
      top: '70%',
    },
    [breakpoints.up('lg')]: {
      top: '64%',
      left: '22%',
    },
  },
  heroSide: {
    backgroundImage: `url("${devImg}")`,
    backgroundPosition: 'right bottom',
    backgroundSize: '80%',
    backgroundRepeat: 'no-repeat',
    minHeight: 500,
    position: 'relative',
    [breakpoints.down('xs')]: {
      backgroundSize: '75%',
      minHeight: 270,
    },
    [breakpoints.between('sm', 'md')]: {
      backgroundSize: '100%',
      minHeight: 515,
    },
  },
  logoContainer: {
    height: 150,
    width: 150,
  },
  order1: {
    [breakpoints.down('sm')]: {
      order: 1,
      paddingBottom: '0!important',
    },
  },
  order2: {
    [breakpoints.down('sm')]: {
      order: 2,
    },
  },
});

export default styles;
